<template>
  <div class="print">
    <div style="margin-top: 30px; margin-bottom: 8px; font-size: 14px">
      共有<span style="color: red">{{ pagination.total }}</span>条数据
    </div>
    <a-table
        style="background-color: #fff"
        rowKey="id"
        bordered
        :columns="columns"
        :data-source="data"
        :pagination="pagination"
        @change="handleTableChange"
    >
      <span slot="action" slot-scope="record">
        <a-button type="primary" @click="print(record)">打印预览</a-button>
      </span>
    </a-table>

    <!--  打印内容区 -->

    <a-modal :width="800" v-model="showModal" title="打印预览">
      <div id="print-area" style="font-size: 14px">
        <div
            style="
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            height: 100%;
            background-color: #fff;
            padding: 0;
            margin: 0;
          "
        >
          <h2>食用农产品质量安全合格证明</h2>
          <div
              style="
              align-self: flex-start;
              line-height: 18px;
              margin-top: 4px;
              margin-left: 12px;
            "
          >
            <span>编号: </span> <span>{{ item.code }}</span>
          </div>
          <div
              style="
              align-self: flex-start;
              line-height: 18px;
              margin-top: 4px;
              margin-left: 12px;
            "
          >
            <span>检测单位: </span> <span>{{ site_name }}</span>
          </div>

          <table
              style="
              text-align: center;
              width: 98%;
              height: 200px;
              margin-top: 8px;
            "
              cellspacing="0"
              border="1px solid #fff"
          >
            <tr>
              <td style="width: 133px; word-wrap: break-word">产品名称</td>
              <td style="width: 133px; word-wrap: break-word">被检单位</td>
              <td style="width: 133px; word-wrap: break-word">联系地址</td>
              <td style="width: 133px; word-wrap: break-word">数量</td>

              <td style="width: 133px; word-wrap: break-word">检测方式</td>
              <td style="width: 133px; word-wrap: break-word">检测结果</td>
            </tr>

            <tr>
              <td>{{ item.name }}</td>
              <td>{{ item.orign }}</td>
              <td>{{ item.address }}</td>
              <td>{{ item.count }}</td>
              <td>{{ item.mode }}</td>
              <td>{{ item.check }}</td>
            </tr>
          </table>

          <div
              style="
              margin-top: 8px;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              font-size: 12px;
              width: 800px;
              word-wrap: no-wrap;
              padding: 0 30px;
            "
          >
            <span>开票日期: {{ time }}</span>
            <span>检测网址: http://2.xinmai.wang/</span>
            <span>联系人: {{ contact_username }}</span>
            <span> 经办人: {{ item.receiver }}</span>
          </div>
        </div>
      </div>
      <template slot="footer">
        <a-button key="back" @click="handleCancel"> 取消</a-button>
        <a-button key="submit" type="primary" @click="handlePrint">
          打印
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import {listSample} from "@/api/sample";
import moment from "moment";
import {getLodop} from "@/utils/LodopFuncs";

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "追溯码",
    dataIndex: "code",
    key: "code",
  },
  {
    title: "产品名称",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "数量",
    dataIndex: "count",
    key: "count",
  },
  {
    title: "检测人",
    dataIndex: "receiver",
    key: "receiver",
  },
  {
    title: "检测结果",
    dataIndex: "check",
    key: "check",
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: {customRender: "action"},
  },
];
const formItemLayout = {
  labelCol: {span: 4},
  wrapperCol: {span: 18},
};
const formTailLayout = {
  labelCol: {span: 4},
  wrapperCol: {span: 18, offset: 4},
};
export default {
  name: "Print",
  data() {
    return {
      data: [],
      columns,
      showModal: false,
      pagination: {
        current: 1,
        total: 0,
        pageSize: 10,
      },
      formItemLayout,
      formTailLayout,
      item: {},
      contact_username: localStorage.getItem("contact_username"),
      time: moment().format("YYYY/MM/DD HH:mm:ss"),
      site_name: localStorage.getItem("site_name"),
      form: this.$form.createForm(this, {name: "dynamic_rule"}),
    };
  },
  methods: {
    handleTableChange(pagination) {
      this.pagination.current = pagination.current;
      this.loadData(this.pagination);
    },
    handleCancel() {
      this.showModal = false;
    },
    loadData() {
      listSample(this.pagination.current, this.pagination.pageSize).then(
          (res) => {
            this.pagination.total = res.data.total;
            this.data = res.data.rows || [];
          }
      );
    },
    print(record) {
      this.item = record;
      this.showModal = true;
    },
    handlePrint() {
      let LODOP = getLodop();
      var html = document.getElementById("print-area").innerHTML;
      // LODOP.SET_PRINT_PAGESIZE(0, 800, 800, "");
      LODOP.PRINT_INIT("合格证");
      // LODOP.SET_PRINT_PAGESIZE(0, 1380, 2450, "A4");
      // LODOP.SET_PRINT_MODE("PRINT_PAGE_PERCENT", "Full-Page"); //按整页缩放
      // LODOP.DO_ACTION("PREVIEW_PERCENT", "15"); //按整页缩放
      // LODOP.SET_PRINT_STYLEA(0, "Stretch", 1);
      // LODOP.SET_PRINT_MODE("PRINT_PAGE_PERCENT", "90%");
      LODOP.SET_PRINT_PAGESIZE(0, 0, 0, "A4");
      LODOP.ADD_PRINT_HTM(0, 0, 800, "BottomMargin:2mm", html);
      LODOP.SET_PRINT_MODE("FULL_WIDTH_FOR_OVERFLOW", true);
      LODOP.PREVIEW();
    },
  },

  mounted() {
    this.loadData();
  },
};
</script>

<style scoped>
</style>